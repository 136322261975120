import { useState } from "react";
import {
  faFilePdf,
  faFilePen,
  faInfinity,
  faLock,
  faMobile,
  faSpinner,
  faVideo,
  faFileLines,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef } from "react";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import SingleCourseCountDown from "./components/SingleCourseCountDown";
import SessionCollapse from "./components/SessionCollapse";
import { Popconfirm } from "antd";
import VdoCiperrPlayer from "./vdoCipherComponents/VdoCiperrPlayer";
import useVdocipher from "./vdoCipherComponents/hooks/useVdocipher";

function SessionType({
  src,
  currentVideo,
  dispatch,
  type,
  views,
  otp,
  playbackInfo,
  videoContainerRef,
  setVideoRef,
  loadVideo,
}) {
  const handleOpenVideo = () => {
    dispatch({
      type: "setPlatform",
      payload: type.platform,
    });
    dispatch({
      type: "setCurrentVideo",
      payload: type.id,
    });
    dispatch({
      type: "setSrc",
      payload: src,
    });
    dispatch({
      type: "setViews",
      payload: views,
    });
    dispatch({
      type: "setTogglePlaylist",
    });

    // vdociper logic
    videoContainerRef.current.innerHTML = "";

    videoContainerRef.current.classList.add("haveVideo");
    const video = loadVideo({
      otp,
      playbackInfo,
      container: videoContainerRef.current,
      configuration: {
        // autoplay: true
      },
    });
    setVideoRef(video);
    // console.log("--------------", type.OTP, playbackInfo);
  };

  return (
    <button
      onClick={
        type.type === "video"
          ? () => {
              handleOpenVideo();
            }
          : null
      }
      className={`hover:bg-secondary hover:text-white transition-all duration-100 ease-in-out px-7 border-y-bg border-y-2 type-video flex justify-between items-center w-full h-10 ${
        currentVideo === type.id &&
        type.type === "video" &&
        " bg-secondary text-white"
      } ${type.is_open === 0 ? "opacity-[.5] !cursor-not-allowed" : ""}  `}
      disabled={type.is_open === 0}
    >
      <div className={`icon-title flex justify-start items-center gap-5 `}>
        <FontAwesomeIcon
          icon={
            type.type === "quiz"
              ? faFilePen
              : type.type === "video"
              ? faVideo
              : type.type === "attachment"
              ? faFilePdf
              : type.type === "homework"
              ? faFileLines
              : ""
          }
        />
        <p>{type.name}</p>
      </div>
      {type.is_open === 0 ? (
        ""
      ) : (
        <>
          {" "}
          {type.type === "quiz" ? (
            <Link
              to={`${
                type.is_take === 1
                  ? `/home/modal-answer/${type.content_id}`
                  : `/home/quiz/${type.id}`
              }`}
              className="session-cta underline underline-offset-4"
            >
              {type.is_take === 1 ? "Modal Answer" : "Take Quiz"}
            </Link>
          ) : type.type === "video" ? (
            <button className="session-cta underline underline-offset-4">
              Watch Video
            </button>
          ) : type.type === "attachment" ? (
            <a
              href={`${type.url}`}
              target="_blank"
              rel="noreferrer"
              className="session-cta underline underline-offset-4"
            >
              Download Attachment
            </a>
          ) : type.type === "homework" ? (
            <Link
              to={`${
                type.is_take === 1
                  ? `/home/modal-answer/${type.content_id}`
                  : `/home/homework/${type.id}`
              }`}
              className="session-cta underline underline-offset-4"
            >
              {type.is_take === 1 ? "Modal Answer" : "Take Homework"}
            </Link>
          ) : (
            ""
          )}{" "}
        </>
      )}
    </button>
  );
}

function SingleCourse() {
  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setOtp": {
        return {
          ...state,
          otp: action.payload,
        };
      }
      case "setPlayBackInfo": {
        return {
          ...state,
          playbackInfo: action.payload,
        };
      }
      case "setViews": {
        return {
          ...state,
          views: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByOffer": {
        return {
          ...state,
          buyLectureByOffer: action.payload,
        };
      }
      case "setBuyLectureByEnroll": {
        return {
          ...state,
          buyLectureByEnroll: action.payload,
        };
      }
      case "setTryLectureByChance": {
        return {
          ...state,
          tryLectureByChance: action.payload,
        };
      }
      case "setActivateFreeCourse": {
        return {
          ...state,
          activateFreeCourse: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }
      case "setTogglePlaylist": {
        return {
          ...state,
          togglePlaylist: !state.togglePlaylist,
        };
      }

      case "setExpireAt": {
        return {
          ...state,
          expireAt: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    otp: "",
    platform: "",
    playbackInfo: "",
    refetch: false,
    currentVideo: "",
    historyId: "",
    togglePlaylist: false,
    views: 0,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByOffer: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByEnroll: {
      flag: "",
      dependency: false,
      data: {},
    },
    tryLectureByChance: {
      flag: "",
      dependency: false,
      data: {},
    },
    activateFreeCourse: {
      flag: "",
      dependency: false,
      data: {},
    },
  });
  const { courseID } = useParams();
  const [videoRef, setVideoRef] = useState(null);
  const videoContainerRef = useRef();
  const { loadVideo, isAPIReady } = useVdocipher();

  //!---- get course info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_COURSE_INFO_API}${courseID}`,
    "GET",
    courseID,
    state.refetch
  );

  //!---- buy course  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_COURSE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );

  //!---- buy course By Code Offer -------
  const [buyLectureOfferSuccess, buyLectureOfferErrors, submitOfferLoading] =
    useAxios(
      process.env.REACT_APP_STUDENT_BUY_COURSE_BY_OFFERCODE_API,
      "POST",
      state.buyLectureByOffer.flag,
      state.buyLectureByOffer.dependency,
      state.buyLectureByOffer.data,
      true
    );

  //!---- buy course By Enroll -------
  const [
    buyLectureByEnrollSuccess,
    buyLectureByEnrollErrors,
    buyLectureByEnrollLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_COURSE_BY_ENROLL_API,
    "POST",
    state.buyLectureByEnroll.flag,
    state.buyLectureByEnroll.dependency,
    state.buyLectureByEnroll.data,
    true
  );

  //!---- buy course By Chance -------
  const [
    TryLectureByChanceSuccess,
    tryLectureByChanceErrors,
    tryLectureByChanceLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_COURSE_BY_CHANCE_API,
    "POST",
    state.tryLectureByChance.flag,
    state.tryLectureByChance.dependency,
    state.tryLectureByChance.data,
    true
  );

  //!---- Activate Free Course -------
  const [
    activateFreeCourseSuccess,
    activateFreeCourseErrors,
    activateFreeCourseLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_ACTIVATE_FREE_COURSE_API,
    "POST",
    state.activateFreeCourse.flag,
    state.activateFreeCourse.dependency,
    state.activateFreeCourse.data,
    true
  );

  //!---refetch the course data again if the code is correct to open the paid content

  useEffect(() => {
    if (
      buyLectureSuccess ||
      buyLectureOfferSuccess ||
      buyLectureByEnrollSuccess ||
      TryLectureByChanceSuccess ||
      activateFreeCourseSuccess
    ) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });

      reset();
      resetOffersData();
    }
  }, [
    buyLectureSuccess,
    buyLectureOfferSuccess,
    buyLectureByEnrollSuccess,
    TryLectureByChanceSuccess,
    activateFreeCourseSuccess,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const {
    register: registerOffers,
    handleSubmit: handleSubmitOffers,
    formState: { errors2, isValid: isValidOffersData },
    reset: resetOffersData,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyLecture",
        dependency: !state.buyLecture.dependency,
        data: { ...data, id: courseID },
      },
    });
  };
  const handleBuyLectureByOffer = (data) => {
    dispatch({
      type: "setBuyLectureByOffer",
      payload: {
        flag: "buyLectureOfferSuccess",
        dependency: !state.buyLectureByOffer.dependency,
        data: { ...data, id: courseID },
      },
    });
  };
  const handleTryLectureByChance = () => {
    dispatch({
      type: "setTryLectureByChance",
      payload: {
        flag: "TryLectureByChanceSuccess",
        dependency: !state.tryLectureByChance.dependency,
        data: { id: courseID },
      },
    });
  };

  const handleBuyLectureByEnroll = (data) => {
    dispatch({
      type: "setBuyLectureByEnroll",
      payload: {
        flag: "buyLectureOfferSuccess",
        dependency: !state.buyLectureByEnroll.dependency,
        data: { id: courseID },
      },
    });
  };

  const handleActivateFreeCourse = (data) => {
    dispatch({
      type: "setActivateFreeCourse",
      payload: {
        flag: "activateFreeCourse",
        dependency: !state.activateFreeCourse.dependency,
        data: { id: courseID },
      },
    });
  };

  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  return (
    <section className="min-h-screen mt-10">
      {/**
       * //!-- top ---
       */}
      <section
        className={`top width ${
          LectureInfoSuccess?.data.course_details?.is_buy === 1 ||
          // LectureInfoSuccess?.data.course_details.price === 0 ||
          LectureInfoSuccess?.data.course_details.is_buying === 0 ||
          LectureInfoSuccess?.data.course_details.n_backcourse === 0
            ? "flex-col"
            : ""
        } flex justify-between items-start gap-20 md:flex-col md:items-center md:justify-start  `}
      >
        <HelmetTags
          title={`${LectureInfoSuccess?.data.course_details?.title} | Mr.Mohamed Salama`}
        />
        {/**
         * //!-- left ---
         */}
        <div
          className={`left ${
            LectureInfoSuccess?.data.course_details?.is_buy === 1 ||
            // LectureInfoSuccess?.data.course_details.price === 0 ||
            LectureInfoSuccess?.data.course_details.is_buying === 0 ||
            LectureInfoSuccess?.data.course_details.n_backcourse === 0
              ? "w-full flex flex-col items-center text-center"
              : "w-full"
          } md:w-full transition-{width}  duration-500 ease-in-out`}
        >
          <div
            className={`course-img-container  rounded-xl overflow-hidden w-full lg:w-full border-2 border-blue  relative    ${
              LectureInfoSuccess?.data.course_details?.is_buy === 1 ||
              // LectureInfoSuccess?.data.course_details.price === 0 ||
              LectureInfoSuccess?.data.course_details.is_buying === 0 ||
              LectureInfoSuccess?.data.course_details.n_backcourse === 0
                ? "max-h-80 h-80"
                : "max-h-80 h-80"
            }  `}
          >
            <img
              className="w-full h-full object-cover cursor-pointer rounded-xl hover:saturate-150 duration-300"
              src={LectureInfoSuccess?.data.course_details?.img}
              alt={LectureInfoSuccess?.data.course_details?.title}
            />
            <div className="absolute course-type top-0 right-0 m-4 bg-blue text-white rounded-tr-lg rounded-bl-lg px-2 py-1 font-medium text-base tracking-wider">
              {LectureInfoSuccess?.data.course_details?.branch}
            </div>
            <div className="absolute course-type top-0 left-0 m-4 bg-blue text-white rounded-tl-lg rounded-br-lg px-2 py-1 font-medium text-base tracking-wider">
              {LectureInfoSuccess?.data.course_details?.branch}
            </div>
            <div className="absolute course-type bottom-0  left-0 m-4 bg-blue text-white rounded-tr-lg rounded-bl-lg px-2 py-1 font-medium text-base tracking-wider">
              {LectureInfoSuccess?.data.course_details?.branch}
            </div>
          </div>
          <div
            className={`title-description border-2 border-blue p-3 rounded-xl mt-5 ${
              LectureInfoSuccess?.data.course_details?.is_buy === 1 ||
              // LectureInfoSuccess?.data.course_details.price === 0 ||
              LectureInfoSuccess?.data.course_details.is_buying === 0
                ? " w-3/4 md:w-full text-center"
                : "w-full text-start"
            }`}
          >
            <h1 className="course-title font-bold text-xl mb-2">
              {LectureInfoSuccess?.data.course_details?.title}
            </h1>
            <h2 className="course-description text-base">
              {LectureInfoSuccess?.data.course_details?.description}
            </h2>
          </div>
          {LectureInfoSuccess?.data.course_details?.is_buy === 0 &&
            LectureInfoSuccess?.data.course_details.is_buying === 0 && (
              <p className="title-description border-2 border-blue p-3 rounded-xl mt-5 w-3/4 md:w-full text-center font-bold text-red-900 border-red-900">
                You Can't Buy This Course Please Contact Us 01154109699!
              </p>
            )}
          {LectureInfoSuccess?.data.course_details.n_backcourse === 0 ? (
            <p className="title-description border-2 border-blue p-3 rounded-xl mt-5 w-3/4 md:w-full text-center font-bold text-red-900 border-red-900">
              You need to buy this Course First:{" "}
              {LectureInfoSuccess?.data.course_details?.backcourse}
            </p>
          ) : null}

          {LectureInfoSuccess?.data.course_details.is_buy ? (
            <SingleCourseCountDown
              expireDate={LectureInfoSuccess?.data?.course_details.expire_at}
            />
          ) : null}
        </div>
        {/**
         * //!-- right ---
         */}
        <div
          className={`right ${
            LectureInfoSuccess?.data.course_details?.is_buy === 1
              ? "w-full"
              : "w-1/2"
          }   md:w-full flex justify-end md:justify-center`}
        >
          {LectureInfoSuccess?.data.course_details?.is_buy === 0 &&
            LectureInfoSuccess?.data.course_details.n_backcourse !== 0 &&
            // LectureInfoSuccess?.data.course_details.price !== 0 &&
            LectureInfoSuccess?.data.course_details.is_buying !== 0 && (
              <div className="course-details-wrapper w-[360px] lg:w-[300px] h-[820px] md:w-full border-2 border-white rounded-xl p-5 shadow-lg text-white relative overflow-hidden flex md:justify-center items-end ">
                <div className="absolute-lock absolute  w-full h-full inset-0 p-5 flex flex-col items-center gap-4">
                  <h3 className="price  font-bold text-3xl text-center my-5">
                    {LectureInfoSuccess?.data.course_details?.price === 0
                      ? "Free"
                      : `${LectureInfoSuccess?.data.course_details?.price} L.E`}
                  </h3>
                  {LectureInfoSuccess?.data.course_details?.price !== 0 && (
                    <form
                      method="post"
                      onSubmit={handleSubmit(handleBuyLecture)}
                      className="flex flex-col w-full gap-5 items-center border-b-2 border-white pb-4"
                    >
                      <input
                        className="code-input pl-4 w-3/4 rounded-lg h-12"
                        placeholder="Add your code"
                        type="text"
                        name="code"
                        {...register("code", {
                          required: true,
                          maxLength: 255,
                          minLength: 1,
                          pattern: /^[A-Za-z0-9-_]*$/,
                        })}
                      />
                      {errors.code && (
                        <p className="text-red-800 text-md ">
                          {errors.code.type === "required" &&
                            "This field is required."}
                          {errors.code.type === "maxLength" &&
                            "code Max length is 255 char."}
                          {errors.code.type === "minLength" &&
                            "code Min length is 1 char."}
                          {errors.code.type === "pattern" &&
                            "Only Letters and Numbers are Allowed."}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        buyLectureErrors &&
                          buyLectureErrors?.response?.data?.errors?.code && (
                            <p className="text-red-800 text-md ">
                              {
                                buyLectureErrors?.response?.data?.errors
                                  ?.code[0]
                              }
                            </p>
                          )
                      }

                      <button
                        disabled={
                          !isValid || (submitLoading && state.buyLecture.flag)
                        }
                        className="submit-buy-course border-2 border-white disabled:border-2 disabled:border-white/60 disabled:text-white/60 w-full h-12 rounded-lg font-bold text-lg"
                        type="submit"
                      >
                        {submitLoading && state.buyLecture.flag ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Buy Course"
                        )}
                      </button>
                    </form>
                  )}

                  {/* Activate Free Course */}
                  {LectureInfoSuccess?.data.course_details?.price === 0 && (
                    <div className="w-full  pb-2">
                      <Popconfirm
                        okText="Confirm"
                        okType="danger"
                        cancelText="Cancel"
                        color="#ffffff" //okButtonProps={{ loading: loading }}
                        onConfirm={handleActivateFreeCourse}
                        title="Are You Sure You Want to Activate Course?"
                      >
                        <button className="submit-buy-course border-2 border-white disabled:border-2 disabled:border-white/60 disabled:text-white/60 w-full h-12 rounded-lg font-bold text-lg mt-4">
                          {activateFreeCourseLoading &&
                          state.activateFreeCourse.flag ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Activate Course"
                          )}
                        </button>
                      </Popconfirm>
                    </div>
                  )}

                  {/* Buy By Offers */}
                  {LectureInfoSuccess?.data.course_details.points !== 0 &&
                    LectureInfoSuccess?.data.course_details?.price !== 0 && (
                      <div className="w-full border-b-2 border-white pb-4">
                        <Popconfirm
                          okText="Confirm"
                          okType="danger"
                          cancelText="Cancel"
                          color="#ffffff" //okButtonProps={{ loading: loading }}
                          onConfirm={handleBuyLectureByEnroll}
                          title="Are You Sure You Want to Buy Course By Offers?"
                        >
                          <button className="submit-buy-course border-2 border-white disabled:border-2 disabled:border-white/60 disabled:text-white/60 w-full h-12 rounded-lg font-bold text-lg mt-4">
                            {buyLectureByEnrollLoading &&
                            state.buyLectureByEnroll.flag ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              "Buy By Offers"
                            )}
                          </button>
                        </Popconfirm>
                      </div>
                    )}
                  {/* use Chances */}
                  {LectureInfoSuccess?.data.course_details.chances > 0 &&
                    LectureInfoSuccess?.data.course_details.n_history_course !==
                      0 &&
                    LectureInfoSuccess?.data.course_details?.price !== 0 && (
                      <div className="w-full border-b-2 border-white pb-2">
                        <Popconfirm
                          okText="Confirm"
                          okType="danger"
                          cancelText="Cancel"
                          color="#ffffff" //okButtonProps={{ loading: loading }}
                          onConfirm={handleTryLectureByChance}
                          title="Are You Sure You Want to Try Course By Chances?"
                        >
                          <button className="submit-buy-course border-2 border-white disabled:border-2 disabled:border-white/60 disabled:text-white/60 w-full h-12 rounded-lg font-bold text-lg mt-4">
                            {tryLectureByChanceLoading &&
                            state.tryLectureByChance.flag ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              "Use Chance"
                            )}
                          </button>
                        </Popconfirm>
                        <p className="mt-2 text-white">
                          Use Chance and Open Course for 24h
                        </p>
                      </div>
                    )}
                  {LectureInfoSuccess?.data.course_details.points === 0 &&
                    LectureInfoSuccess?.data.course_details?.price !== 0 && (
                      <form
                        method="post"
                        onSubmit={handleSubmitOffers(handleBuyLectureByOffer)}
                        className="flex flex-col w-full gap-5 items-center h-1/2"
                      >
                        <input
                          className="code-input pl-4 w-3/4 rounded-lg h-12"
                          placeholder="Add your Offer Code"
                          type="text"
                          name="offerCode"
                          {...registerOffers("offerCode", {
                            required: true,
                            maxLength: 255,
                            minLength: 1,
                            pattern: /^[A-Za-z0-9-_]*$/,
                          })}
                        />
                        {errors2?.offerCode && (
                          <p className="text-red-800 text-md ">
                            {errors2?.offerCode.type === "required" &&
                              "This field is required."}
                            {errors2?.offerCode.type === "maxLength" &&
                              "offerCode Max length is 255 char."}
                            {errors2?.offerCode.type === "minLength" &&
                              "offerCode Min length is 1 char."}
                            {errors2?.offerCode.type === "pattern" &&
                              "Only Letters and Numbers are Allowed."}
                          </p>
                        )}
                        {
                          //!-------server errors2 -----

                          buyLectureOfferErrors &&
                            buyLectureOfferErrors?.response?.data?.errors
                              ?.offerCode && (
                              <p className="text-red-800 text-md ">
                                {
                                  buyLectureOfferErrors?.response?.data?.errors
                                    ?.offerCode[0]
                                }
                              </p>
                            )
                        }

                        <button
                          disabled={
                            !isValidOffersData ||
                            (submitOfferLoading && state.buyLectureByOffer.flag)
                          }
                          className="submit-buy-course border-2 border-white disabled:border-2 disabled:border-white/60 disabled:text-white/60 w-full h-12 rounded-lg font-bold text-lg"
                          type="submit"
                        >
                          {submitOfferLoading &&
                          state.buyLectureByOffer.flag ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Buy Course"
                          )}
                        </button>
                      </form>
                    )}
                  <FontAwesomeIcon
                    className="text-9xl w-full h-1/2  mx-auto opacity-40"
                    icon={faLock}
                  />
                </div>
                <div className="details-container  p-5  rounded-lg">
                  <h2 className="font-bold text-2xl lg:text-xl text-start md:text-center my-7">
                    This course includes:
                  </h2>
                  <ul className="flex flex-col items-start justify-start  gap-5">
                    <li className="flex justify-start items-center gap-4">
                      <FontAwesomeIcon icon={faFileLines} /> <p>Assignments</p>
                    </li>
                    <li className="flex justify-start items-center gap-4">
                      <FontAwesomeIcon icon={faInfinity} />{" "}
                      <p>Full lifetime access.</p>
                    </li>
                    <li className="flex justify-start items-center gap-4">
                      <FontAwesomeIcon icon={faMobile} />{" "}
                      <p>Access on mobile and Laptop.</p>
                    </li>
                  </ul>
                </div>
              </div>
            )}
        </div>
      </section>

      {/* conent free  */}
      {LectureInfoSuccess?.data.course_details?.is_buy === 0 && (
        <div className="video-playlist relative  min-h-screen flex  justify-between items-center gap-10 lg:gap-16 md:flex-col md:items-center md:justify-center  ">
          <div
            className={`playlist absolute top-[-50px] md:top-10 transition-all duration-300   md:w-full flex items-end flex-col ${
              state.togglePlaylist
                ? "w-[60px] overflow-hidden pointer-events-none"
                : "w-[400px] "
            }`}
          >
            <div
              onClick={() => dispatch({ type: "setTogglePlaylist" })}
              className="toggle-playlist-wrapper pointer-events-auto bg-blue text-white text-xl cursor-pointer flex justify-center items-center mb-2 w-10 h-10 "
            >
              <FontAwesomeIcon
                className={`transition-all duration-300 ${
                  state.togglePlaylist ? "rotate-180 " : "rotate-0"
                }`}
                icon={faChevronLeft}
              />
            </div>
            <div
              className={`course-header-content relative transition-all duration-300  ${
                state.togglePlaylist
                  ? "w-0 pointer-events-none border-none"
                  : "w-full border-2 border-blue "
              }   overflow-y-auto h-[70vh] md:h-[70vh]`}
            >
              <div className="course-header bg-blue text-white p-4 shadow-lg w-full ">
                <h2 className="course-title md:text-center text-xl font-bold">
                  Course Content
                </h2>
                <p className="course-details md:text-center mt-3 ">
                  {LectureInfoSuccess?.data.sessions.length} sections
                </p>
              </div>
              <div
                className={`playlist course-content w-full  rounded-  overflow-hidden bg-bg  transition-{height} duration-500 ease-in-out `}
              >
                {LectureInfoSuccess?.data.sessions?.map((session, index) => (
                  <div
                    key={index}
                    className={`session-collapsed transition-{height} duration-700 ease-in-out w-full   `}
                  >
                    {/**
                     * //!---session-title-wrapper-border-y ----
                     */}
                    <SessionCollapse
                      session={session}
                      dispatch={dispatch}
                      state={state}
                    />
                    {/**
                     * //!---session-content-----
                     */}
                    <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit py-4">
                      {session.session?.map((type) => {
                        let src;
                        if (type.type === "video") {
                          src =
                            type.platform === "youtube"
                              ? `https://www.youtube.com/embed/${type.vdo_id}`
                              : type.platform === "vimeo"
                              ? `https://player.vimeo.com/video/${type.vdo_id}`
                              : `https://player.vdocipher.com/v2/?otp=${type.OTP}&playbackInfo=${type.playbackInfo}`;
                        }

                        return (
                          <SessionType
                            key={type.id}
                            src={src}
                            otp={type.OTP}
                            playbackInfo={type.playbackInfo}
                            currentVideo={state.currentVideo}
                            dispatch={dispatch}
                            type={type}
                            views={type.views}
                            setVideoRef={setVideoRef}
                            videoContainerRef={videoContainerRef}
                            loadVideo={loadVideo}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {state.src && (
            <div className="flex flex-col w-full items-center justify-center gap-3">
              <div className="video  width flex justify-end">
                <div
                  className={`video transition-all duration-300  ${
                    state.togglePlaylist
                      ? "w-full justify-center"
                      : "w-3/5 justify-end"
                  }  md:w-full flex  md:justify-center `}
                >
                  <div className="iframe-wrapper aspect-video  w-9/12 md:w-full overflow-hidden rounded-xl border-2">
                    <iframe
                      src={state.src}
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      frameBorder="0"
                      //style={{ aspectRatio: "16 / 9" }}
                      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
              {state.views && (
                <p className="font-bold text-xl">views: {state.views}</p>
              )}
            </div>
          )}
        </div>
      )}

      {/*bottom  playlist - video */}
      {LectureInfoSuccess?.data.course_details?.is_buy === 1 && (
        <div className="video-playlist  min-h-screen flex  justify-between items-center gap-10 lg:gap-16 md:flex-col md:items-center md:justify-center  ">
          <div
            className={`playlist absolute transition-all duration-300   md:w-full flex items-end flex-col ${
              state.togglePlaylist
                ? "w-[60px] overflow-hidden pointer-events-none"
                : "w-[400px] "
            }`}
          >
            <div
              onClick={() => dispatch({ type: "setTogglePlaylist" })}
              className="toggle-playlist-wrapper pointer-events-auto bg-blue text-white text-xl cursor-pointer flex justify-center items-center mb-2 w-10 h-10 "
            >
              <FontAwesomeIcon
                className={`transition-all duration-300 ${
                  state.togglePlaylist ? "rotate-180 " : "rotate-0"
                }`}
                icon={faChevronLeft}
              />
            </div>
            <div
              className={`course-header-content relative transition-all duration-300  ${
                state.togglePlaylist
                  ? "w-0 pointer-events-none border-none"
                  : "w-full border-2 border-blue "
              }   overflow-y-auto h-[70vh] md:h-[70vh]`}
            >
              <div className="course-header bg-blue text-white p-4 shadow-lg w-full ">
                <h2 className="course-title md:text-center text-xl font-bold">
                  Course Content
                </h2>
                <p className="course-details md:text-center mt-3 ">
                  {LectureInfoSuccess?.data.sessions.length} sections
                </p>
              </div>
              <div
                className={`playlist course-content w-full  rounded-  overflow-hidden bg-bg  transition-{height} duration-500 ease-in-out `}
              >
                {/* {LectureInfoSuccess?.data?.previous_homework && (
                  <div className="hover:bg-secondary hover:text-white transition-all duration-100 ease-in-out px-7 border-y-bg border-y-2 type-video flex justify-between items-center w-full h-10 ">
                    <div
                      className={`icon-title flex justify-start items-center gap-5 `}
                    >
                      <FontAwesomeIcon icon={faFileLines} />
                      <p>Previous Homework</p>
                    </div>
                    <Link
                      to={`${
                        LectureInfoSuccess?.data.previous_homework.is_done === 1
                          ? `/home/previous-homework-modal-answer/${LectureInfoSuccess?.data.course_details.key}`
                          : `/home/previous-homework/${LectureInfoSuccess?.data.course_details.key}`
                      }`}
                      className="session-cta underline underline-offset-4"
                    >
                      {LectureInfoSuccess?.data.previous_homework.is_done === 1
                        ? "Modal Answer"
                        : "Take Homework"}
                    </Link>
                  </div>
                )} */}

                {LectureInfoSuccess?.data.sessions?.map((session, index) => (
                  <div
                    key={index}
                    className={`session-collapsed transition-{height} duration-700 ease-in-out w-full `}
                  >
                    {/**
                     * //!---session-title-wrapper-border-y ----
                     */}
                    <SessionCollapse
                      session={session}
                      dispatch={dispatch}
                      state={state}
                    />
                    {/**
                     * //!---session-content-----
                     */}
                    <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit py-4">
                      {session.session?.map((type) => {
                        let src;
                        if (type.type === "video") {
                          src =
                            type.platform === "youtube"
                              ? `https://www.youtube.com/embed/${type.vdo_id}`
                              : type.platform === "vimeo"
                              ? `https://player.vimeo.com/video/${type.vdo_id}`
                              : `https://player.vdocipher.com/v2/?otp=${type.OTP}&playbackInfo=${type.playbackInfo}`;
                        }

                        return (
                          <SessionType
                            key={type.id}
                            src={src}
                            otp={type.OTP}
                            playbackInfo={type.playbackInfo}
                            currentVideo={state.currentVideo}
                            dispatch={dispatch}
                            type={type}
                            views={type.views}
                            setVideoRef={setVideoRef}
                            videoContainerRef={videoContainerRef}
                            loadVideo={loadVideo}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`
            
            ${
              state.src ? "flex" : "hidden"
            } flex-col w-full items-center justify-center gap-3`}
          >
            <div
              className={`
              ${
                state.views === 0 ? "hidden" : "flex"
              } video  width justify-end`}
            >
              <div
                className={`video transition-all duration-300  ${
                  state.togglePlaylist
                    ? "w-full justify-center"
                    : "w-3/5 justify-end"
                }  md:w-full flex  md:justify-center `}
              >
                <div className="iframe-wrapper aspect-video w-9/12 md:w-full overflow-hidden rounded-xl border-2">
                  <VdoCiperrPlayer
                    videoId={state.currentVideo}
                    platform={state.platform}
                    // views={state.views}
                    history_id={
                      LectureInfoSuccess?.data.course_details.history_id
                    }
                    videoRef={videoRef}
                    videoContainerRef={videoContainerRef}
                    isAPIReady={isAPIReady}
                    setVideoRef={setVideoRef}
                  />
                  {state.platform !== "vdocipher" && (
                    <iframe
                      src={state.src}
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      frameBorder="0"
                      //style={{ aspectRatio: "16 / 9" }}
                      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      id="courseVideo"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
            {state.platform === "vdocipher" && (
              <p className="font-bold text-xl">
                {state.views === 0
                  ? "You have exceeded the viewing limit"
                  : `views: ${state.views}`}
              </p>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default SingleCourse;
