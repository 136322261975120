//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditExam({ edit }) {
  const [values, setValues] = useState(null);
  const [lectureData, setLectureData] = useState([]);
  const { ID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditExam": {
        return {
          ...state,
          submitAddEditExam: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditExam: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_TRIAL_EXAM_API
    : process.env.REACT_APP_ADMIN_ADD_TRIAL_EXAM_API;

  //!--------- add edit exam -------
  const [ExamAddEditSuccess, ExamAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditExam.flag,
    state.submitAddEditExam.dependency,
    state.submitAddEditExam.data,
    true
  );

  // get all lectures in exam
  const [allExamsLecturesData, allExamsLecturesErrors] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ALL_TRIAL_EXAMS_LECTURES_API,
    "GET",
    "GET",
    ""
  );

  //!--------- get the exam info for editing -------

  const [ExamInfo, ExamInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_INFO_API}/${ID}`,
    "GET",
    ID,
    ID
  );
  useEffect(() => {
    if (ExamInfo) {
      let temp = ExamInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        next_course: temp.next_course === null ? 0 : temp.next_course,
      });
    }
  }, [ExamInfo]);
  useEffect(() => {
    if (allExamsLecturesData) {
      setLectureData(allExamsLecturesData?.data);
    }
  }, [allExamsLecturesData]);

  useEffect(() => {
    if (ExamAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ExamAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onTouched", values });
  const stageWatch = watch("stage");

  const onSubmit = (data) => {
    const finalData = ID
      ? {
          ...data,
          id: ID,
          img: data?.img[0] || null,
          visibility: +data?.visibility,
          v_model: +data?.v_model,
          is_buying: +data?.is_buying,
        }
      : {
          ...data,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditExam",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditExam.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags name="Trial Exams | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {ID ? "Edit Trial Exam" : "Add Trial Exam"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name stage filed */}
          <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:gap-6 md:flex-col-reverse  ">
            <div
              className={`flex w-1/2 flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="" htmlFor="stage">
                Stage
              </label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.stage.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.stage && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {ExamAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs"
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 80,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className=" pt-2 text-xs text-red-500  ">
                  {errors.name.type === "required" && "This field is required."}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed."}
                  {errors.name.type === "maxLength" &&
                    "Allowed Name max length is 80."}
                  {errors.name.type === "minLength" &&
                    "Please enter at least 3 letters."}
                </p>
              )}

              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.name && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {ExamAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="back_course">Back Course</label>
              <select
                name="back_course"
                id="back_course"
                {...register("back_course", { required: false })}
              >
                <option className="opacity-50 bg-white text-black" value={0}>
                  No Back Course
                </option>
                {lectureData
                  .filter((lecture) => lecture.stage === +stageWatch)
                  .map((lecture) => (
                    <option value={lecture.key}>{lecture.name}</option>
                  ))}
              </select>

              {errors.lecture && (
                <p className="text-[12px] text-red-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="next_course">Next Course</label>
              <select
                name="next_course"
                id="next_course"
                {...register("next_course", { required: false })}
              >
                <option className="opacity-50 bg-white text-black" value={0}>
                  No Next Course
                </option>
                {lectureData
                  .filter((lecture) => lecture.stage === +stageWatch)
                  .map((lecture) => (
                    <option value={lecture.key}>{lecture.name}</option>
                  ))}
              </select>

              {errors.lecture && (
                <p className="text-[12px] text-red-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            {/* success_rate */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="success_rate">Success rate</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="success_rate"
                placeholder="Success rate"
                name="success_rate"
                autoComplete="on"
                {...register("success_rate", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.success_rate && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {errors.success_rate.type === "required" &&
                    "Please fill out this field"}
                  {errors.success_rate.type === "minLength" &&
                    "من فضلك قم بكتابة 1 أحرف علي الاقل"}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            {/* duration */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="duration">Duration</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="duration"
                placeholder="Duration"
                name="duration"
                autoComplete="on"
                {...register("duration", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.duration && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {errors.duration.type === "required" &&
                    "Please fill out this field"}
                  {errors.duration.type === "minLength" &&
                    "من فضلك قم بكتابة 1 أحرف علي الاقل"}
                </p>
              )}

              {errors.lecture && (
                <p className="text-[12px] text-red-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-1/3 md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="is_buying"
                      name="  "
                      {...register("is_buying", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="is_buying"
                    >
                      Exam Availability
                    </label>
                  </div>
                </div>

                {errors.is_buying && (
                  <p className="text-[12px] text-red-900 ">
                    {errors.is_buying.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.is_buying && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.is_buying[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="v_model"
                      name="v_model"
                      {...register("v_model", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="v_model"
                    >
                      Show Modal Ansser
                    </label>
                  </div>
                </div>

                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.v_model && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.v_model[0]}
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      visibility{" "}
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-red-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Exam Image  */}
          <div className="flex w-full flex-col items-start gap-2">
            <label className=" " htmlFor="img">
              Trial Exam Image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className=" pt-2 text-xs text-red-500">
                {errors.img.type === "required" && "This field is required."}
                {errors.img.type === "validate" && "Image Max Size is 5 MB."}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.img && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {ExamAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-start justify-center gap-2 ">
            <label className=" " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="pt-2 text-xs text-red-500 ">
                {errors.description.type === "maxLength" &&
                  "Allowed Description Max Length is 155."}
                {errors.description.type === "minLength" &&
                  "Please Enter at Least 3 Letters."}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.description && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {ExamAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditExam.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditExam.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {ID ? "Confirm Editing The Trial Exam" : "Add The Trial Exam"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditExam;
