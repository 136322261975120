import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faLock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";

function AdminCourses({ admin }) {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const observer = useRef();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const initialState = {
    left: "left-0",
    top: "top-0",
    toggleFilter: true,
    selectedType: "quiz",
    courseType: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "setLeft": {
        return {
          ...state,
          left: action.payload,
        };
      }
      case "setTop": {
        return {
          ...state,
          top: action.payload,
        };
      }
      case "setToggleFilter": {
        return {
          ...state,
          toggleFilter: !state.toggleFilter,
        };
      }
      case "setSelectedType": {
        return {
          ...state,
          selectedType: action.payload,
        };
      }
      case "setCourseType": {
        return {
          ...state,
          courseType: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [stage, setStage] = useState(1); //!--------- get all Courses -------
  const [filteredCourses, setFilteredCourses] = useState([]); //!--------- get all Courses -------

  const [allCoursesData, allCoursesErrors, allCoursesloading] = useAxios(
    process.env.REACT_APP_ADMIN_COURSES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  let lastElement = useCallback(
    (val) => {
      if (allCoursesloading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((item) => {
        if (item[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });

      if (val) {
        observer.current.observe(val);
      }
    },
    [allCoursesloading]
  );

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Course -------

  const [deleteCourseSuccess, deleteCourseErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_COURSE_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteCourse(id) {
    setSubmitDelete({
      flag: "deleteCourse",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete
    if (deleteCourseSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteCourseSuccess]);

  useEffect(() => {
    setFilteredCourses(allCoursesData?.data);
  }, [allCoursesData]);

  //!- Filtering ----
  useEffect(() => {
    if (state.courseType === "") {
      setFilteredCourses(allCoursesData?.data);
    } else {
      let filtered = allCoursesData?.data?.filter(
        (course) => course.branch === state.courseType
      );
      setFilteredCourses(filtered);
    }
  }, [state.courseType]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Courses | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-start gap-10 amd-hero:items-center">
        <h2 className=" text-2xl font-bold">Courses</h2>
        <div className="flex w-full justify-start gap-7 md:justify-center items-center">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_add")
            )) && (
            <Link
              to="/admin/courses/add-course"
              className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 "
            >
              Add Course <FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Link>
          )}
          {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("offer")
            )) && (
            <Link
              to="/admin/courses/offers"
              className=" rounded-lg border-2 border-secondary bg-bg px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-bg   active:scale-90 "
            >
              Offers
            </Link>
          )} */}
        </div>
        <div
          className={`filters-container bg-light-gray w-full flex justify-center `}
        >
          <div
            className={`filters flex justify-between  items-center w-[800px] h-12 relative amd-hero:flex-col  amd-hero:w-28  transition-all duration-200 ease-in-out ${
              state.toggleFilter
                ? "amd-hero:h-[450px] "
                : "amd-hero:h-[calc(450px/9)]"
            }`}
          >
            <div
              onClick={() => {
                dispatch({ type: "setToggleFilter" });
                dispatch({ type: "setTop", payload: "top-0" });
              }}
              className="ALL hidden w-1/5 amd-hero:flex justify-center items-center h-full min-h-[calc(450px/9)] cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)]  amd-hero:w-28 "
            >
              <FontAwesomeIcon
                className={`hidden amd-hero:block  font-bold text-2xl transition-all duration-200 ease-in-out ${
                  state.toggleFilter && "rotate-180"
                }`}
                icon={faChevronDown}
              />
            </div>

            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-0" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*1)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "",
                });
              }}
              className={`calculus  w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              All{" "}
            </div>
            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[12.5%]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*2)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "calculus",
                });
              }}
              className={`calculus  w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Calculus{" "}
            </div>

            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*2)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*3)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "algebra",
                });
              }}
              className={`Algebra w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Algebra{" "}
            </div>

            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*3)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*4)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "statistics",
                });
              }}
              className={`Statistics w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Statistics{" "}
            </div>

            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*4)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*5)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "dynamics",
                });
              }}
              className={`Dynamics w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Dynamics{" "}
            </div>
            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*5)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*6)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "statics",
                });
              }}
              className={`Dynamics w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Statics{" "}
            </div>
            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*6)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*7)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "applied",
                });
              }}
              className={`Dynamics w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Applied{" "}
            </div>
            <div
              onClick={() => {
                dispatch({ type: "setLeft", payload: "left-[calc(12.5%*7)]" });
                dispatch({
                  type: "setTop",
                  payload: "top-[calc((450px/9)*8)]",
                });
                dispatch({
                  type: "setCourseType",
                  payload: "pure",
                });
              }}
              className={`Dynamics w-[12.5%]  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                state.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(450px/9)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Pure{" "}
            </div>

            <div
              className={`selector-desktop amd-hero:hidden absolute w-[12.5%]  h-full  ${state.left} amd-hero:left-0 bg-green duration-300 rounded-md amd-hero:h-[calc(450px/9)] amd-hero:w-28  top-0`}
            ></div>
            <div
              className={`selector-mobile hidden amd-hero:block absolute h-full  left-0 bg-green duration-300 rounded-md amd-hero:h-[calc(450px/9)] amd-hero:w-28 ${state.top} `}
            ></div>
          </div>
        </div>

        <div
          className={`filters-container bg-light-gray w-full flex justify-center `}
        >
          <div
            className={`filters flex justify-between  items-center w-[800px] h-12 relative amd-hero:flex-col  amd-hero:w-28  transition-all duration-200 ease-in-out ${
              state.toggleFilter
                ? "amd-hero:h-[450px] "
                : "amd-hero:h-[calc(450px/9)]"
            }`}
          >
            <div
              onClick={() => {
                dispatch({ type: "setToggleFilter" });
                dispatch({ type: "setTop", payload: "top-0" });
              }}
              className="ALL hidden w-1/5 amd-hero:flex justify-center items-center h-full min-h-[calc(450px/9)] cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)]  amd-hero:w-28 "
            >
              <FontAwesomeIcon
                className={`hidden amd-hero:block  font-bold text-2xl transition-all duration-200 ease-in-out ${
                  state.toggleFilter && "rotate-180"
                }`}
                icon={faChevronDown}
              />
            </div>

            <div
              onClick={() => {
                setStage(1);
              }}
              className={`calculus  w-1/3 flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                stage === 1 ? "bg-green" : ""
              }`}
            >
              Stage 1
            </div>
            <div
              onClick={() => {
                setStage(2);
              }}
              className={`calculus  w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                stage === 2 ? "bg-green" : ""
              }`}
            >
              Stage 2
            </div>

            <div
              onClick={() => {
                setStage(3);
              }}
              className={`Algebra w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                stage === 3 ? "bg-green" : ""
              }`}
            >
              Stage 3
            </div>
          </div>
        </div>
      </div>
      {
        <div className="courses w-full ">
          {allCoursesErrors ? (
            <p>Something Wrong has happened, Please Try Again.</p>
          ) : filteredCourses?.length === 0 ? (
            <p>There are No Courses Yet.</p>
          ) : (
            <div className={`courses  mt-10 grid-auto-fit w-full `}>
              {filteredCourses
                ?.filter((course) => course.stage === stage)
                .map((course, index) => {
                  return (
                    <div
                      ref={
                        filteredCourses?.length === index + 1
                          ? lastElement
                          : null
                      }
                      key={course.key}
                      className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px]   rounded-md p-4 shadow-lg`}
                    >
                      <div className="img-wrapper w-full rounded-md overflow-hidden">
                        <img
                          className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
                          src={course.img}
                          alt={course.title}
                          onClick={() =>
                            navigate(
                              admin
                                ? `/admin/courses/${course.key}`
                                : `/home/courses/${course.key}`
                            )
                          }
                        />
                      </div>

                      <div className="details-wrapper mt-5">
                        <div className="type-hours w-full flex justify-between items-center">
                          <div className="type rounded-lg px-2 bg-pink text-white cursor-pointer">
                            {course.type}
                          </div>
                          <div className="hours">
                            <p className="">
                              {course.n_sessions} Sessions |{" "}
                              {course.price === 0
                                ? "Free"
                                : `${course.price} EGP`}{" "}
                              | Stage {course.stage}
                            </p>
                          </div>
                        </div>

                        <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
                          <Link
                            to={
                              admin
                                ? `/admin/courses/${course.key}`
                                : `/home/courses/${course.key}`
                            }
                            className="cursor-pointer w-3/5  truncate"
                          >
                            {course.title}
                          </Link>
                          <span className="font-normal"> {course.type}</span>
                        </h2>
                        <p className="description">{course.description}</p>

                        <div className="price-cta flex justify-between items-center mt-5 w-full">
                          {admin ? (
                            <>
                              <div className="admin-btns flex justify-end items-center gap-4  w-full">
                                {(AuthState.userData?.admin?.roles[0]?.name ===
                                  "superadmin" ||
                                  AuthState.userData?.admin?.permissions?.some(
                                    (permission) =>
                                      permission.name.startsWith("lecture_view")
                                  )) && (
                                  <Link
                                    to={`/admin/courses/view/${course.key}`}
                                    className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                                  >
                                    View
                                  </Link>
                                )}
                                {(AuthState.userData?.admin?.roles[0]?.name ===
                                  "superadmin" ||
                                  AuthState.userData?.admin?.permissions?.some(
                                    (permission) =>
                                      permission.name.startsWith(
                                        "lecture_delete"
                                      )
                                  )) && (
                                  <Popconfirm
                                    okText="Confirm"
                                    okType="danger"
                                    cancelText="Cancel"
                                    color="#ffffff"
                                    //okButtonProps={{ loading: loading }}
                                    onConfirm={() =>
                                      handleDeleteCourse(course.key)
                                    }
                                    title="Are You Sure You Want to Delete The Course?"
                                  >
                                    <button className="delete-btn   bg-delete text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-delete hover:bg-bg hover:text-delete transition-all duration-300  hover:scale-105 active:scale-90">
                                      Delete
                                    </button>
                                  </Popconfirm>
                                )}
                                {(AuthState.userData?.admin?.roles[0]?.name ===
                                  "superadmin" ||
                                  AuthState.userData?.admin?.permissions?.some(
                                    (permission) =>
                                      permission.name.startsWith("lecture_edit")
                                  )) && (
                                  <Link
                                    to={`/admin/courses/edit-course/${course.key}`}
                                    className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                                  >
                                    Edit
                                  </Link>
                                )}
                              </div>
                            </>
                          ) : (
                            <Link
                              to="/"
                              className="flex justify-center
               items-center gap-2 bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
                            >
                              <FontAwesomeIcon icon={faLock} />
                              Buy Course
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      }
      {allCoursesloading && <Loader />}
    </section>
  );
}

export default AdminCourses;
