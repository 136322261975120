import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

const AdminViewExam = () => {
  const { ID } = useParams();
  const table = useTable("viewStudentExam", "", "", "", "", ID);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Trial Exams | Mr.Mohamed Salama"></HelmetTags>{" "}
      <h2 className=" text-start text-2xl font-bold ">Students</h2>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminViewExam;
